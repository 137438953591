
<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item"><a href="/associates">Sócios</a></li>
      <li class="breadcrumb-item active" aria-current="page">Novo</li>
    </ol>
  </nav>
  <Form :save="parentMethod" />
</template>

<script>
import Form from './Form.vue';
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  components: {
    Form,
  },
  methods: {
    async parentMethod(associate) {
      this.$root.loadingVisible = true;
      ApiAxios.post(`associates`, { associate })
        .then((res) => {
          this.$root.loadingVisible = false;
          sAlert({ type: 'success', text: 'Registro gravado com sucesso.' });
          this.$router.push({ name: 'associateEdit', params: { id: res.data.associate.id }});
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403 || e.response.status == 400) return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data }); 
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
    }
  },  
}
</script>  
